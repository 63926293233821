import React from 'react';

function Analytics() {
  return (
     <div>
       <a title="hit counter" href="https://statcounter.com/" target="_blank">
         <img src="https://c.statcounter.com/12916898/0/9e9b5e41/1/" alt="hit counter"/>
       </a>
     </div>
  );
}

export default Analytics;
